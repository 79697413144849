<template>
  <div>
    <div class="header">
      <img class="img" src="../assets/tick.svg" />
      <h5 class="lead font-weight-bold mt-5">La cita fue creada con éxito</h5>
      <span class="lead">
        Gracias por preferirinos. Te estaremos contactando para confirmar la visita.
      </span>
    </div>

    <ul class="list-group list-group-flush">
      <h5 class="lead text-left font-weight-bold ">Detalle de la cita</h5>
      <li class="list-group-item">
        <div class="row">
          <div class="col-6 pl-0 align-self-center text-left">
            <b>Código</b>
          </div>
          <div class="col-6 pr-0 align-self-center text-right" id="resume-code">
            {{ date.code }}
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-6 pl-0 align-self-center text-left">
            <b>Fecha</b>
          </div>
          <div class="col-6 pr-0 align-self-center text-right">
            {{ date.day }}
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-6 pl-0 align-self-center text-left">
            <b>Hora</b>
          </div>
          <div class="col-6 pr-0 align-self-center text-right">
            {{ date.hour }}
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-6 pl-0 align-self-center text-left">
            <b>Motivo</b>
          </div>
          <div class="col-6 pr-0 align-self-center text-right">
            {{ date.reason }}
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-6 pl-0 align-self-center text-left">
            <b>Veterinario</b>
          </div>
          <div class="col-6 pr-0 align-self-center text-right">
            {{ date.veterinary }}
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-6 pl-0 align-self-center text-left">
            <b>Sucursal</b>
          </div>
          <div class="col-6 pr-0 align-self-center text-right">
            {{ date.office.name }}
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-6 pl-0 align-self-center text-left">
            <b>Dirección</b>
          </div>
          <div class="col-6 pr-0 align-self-center text-right">{{ date.office.address }}</div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-6 pl-0 align-self-center text-left">
            <b>Teléfono de La sucursal</b>
          </div>
          <div class="col-6 pr-0 align-self-center text-right">{{ date.office.phone }}</div>
        </div>
      </li>
    </ul>
    <div class="btn-container">
      <button type="button" class="btn-end btn btn-success" @click="finish">Finalizar</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["date"]),
  },
  methods: {
    finish() {
      this.$store.commit("resetAllData");
      this.$store.commit("resetInitData");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.img {
  width: 80px;
}
.btn-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.header {
  padding-top: 5%;
  text-align: center;
  margin-bottom: 2%;
}
.btn-end {
  width: 100%;
  padding: 20px;
  background: #1e7e34;
  font-size: 17px;
  font-weight: 700;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  padding: 50px;
  padding-top: 0px;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.95rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.text-left {
  text-align: left !important;
}
.list-group-flush {
  border-radius: 0;
}
.text-right {
  text-align: right !important;
}
.align-self-center {
  align-self: center !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}

@media (max-width: 425px) {
  .list-group {
    padding: 0;
    margin-top: 23px;
  }
  .lead {
    font-size: 1.1rem;
  }
}
</style>
